export const environment = {
  production: true,
  cdnUrl: 'https://cdn.studiobx.dev/',
  apiUrl: 'https://api.studiobx.dev/',
  frontUrl: 'https://www.studiobx.dev/',
  portalUrl: 'https://portal.studiobx.dev/',
  systemKey: 'studiobx',
  salesWebsite: 'https://over.studiobx.nl/',
  contactEmail: 'info@studiobx.nl',
  contactPhone: '0031003333333',
  title: 'Studio BX'
};